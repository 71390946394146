import clsx from "clsx"
import { Element } from "domhandler"
import { sanitize } from "dompurify"
import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core"
import parse, { HTMLReactParserOptions, domToReact } from "html-react-parser"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"

const useStyles = makeStyles((_theme) => ({
    "wrapper": {
        "& * > img": {
            width: "100%",
            height: "auto",
        }
    }
}))

const parserOptions: HTMLReactParserOptions = {
    replace: (node) => {
        // We need to replace links with our custom component so that they will open correctly under Cordova
        if (node instanceof Element && node.name === "a") {
            return (
                <ExternalLink href={node.attribs["href"]} color="primary">{domToReact(node.children, parserOptions)}</ExternalLink>
            )
        }

        return
    }
}

type DynamicHtmlProps = Readonly<{
    html: string
    className?: string
}>

export function DynamicHtml({ html, className }: DynamicHtmlProps) {
    const classes = useStyles()

    // Even though API should always sanitize HTML from user input, we perform a client-side sanitization for defense-in-depth
    // NOTE: We disallow MathML and SVG content since they should not be needed when content comes from our rich text editor
    const sanitized = useMemo(() => sanitize(html, { USE_PROFILES: { html: true }, ALLOW_UNKNOWN_PROTOCOLS: false }), [html, sanitize])
    const components = useMemo(() => parse(sanitized, parserOptions), [sanitized, parse])

    return (
        <div className={clsx(className, classes.wrapper)}>
            {components}
        </div>
    )
}
