import React, { useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import "swiper/swiper-bundle.min.css"
import { Swiper as ISwiper, FreeMode } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: "100vw",
        overflowX: "hidden",
        marginRight: -15,
        marginLeft: -15,
    },
    child: {
        display: "flex",
        paddingLeft: 15,
        position: "relative",
    },
    swiper: {
        zIndex: "auto",
        "& > div": {
            zIndex: "auto",
        },
    },
}))

interface IProps {
    children: any | any[]
    slidesPerView?: number
    slideSize?: CardCarouselSize
}

export enum CardCarouselSize {
    S = "small",
    M = "medium",
}

const getSlideStyles = (slideSize: CardCarouselSize) => {
    switch (slideSize) {
        case CardCarouselSize.S:
            return {
                width: "calc(100% - 185px)",
                spaceBetween: -185,
            }
        case CardCarouselSize.M:
            return {
                width: "calc(100% - 70px)",
                spaceBetween: -70,
            }
        default:
            return {
                width: "calc(100% - 70px)",
                spaceBetween: -70,
            }
    }
}

export default function ({ children, slidesPerView = 1, slideSize = CardCarouselSize.M }: IProps) {
    if (typeof children.length !== "number" || children.length === 1) {
        return <div>{children}</div>
    }

    const classes = useStyles()
    const swiperRef = useRef<ISwiper>()

    function slideTo(index: number) {
        if (swiperRef.current) {
            swiperRef.current.slideTo(index)
        }
    }

    useEffect(() => {
        if (slideTo) return slideTo(0)
    }, [children.length])

    const { width, spaceBetween } = getSlideStyles(slideSize)

    const carousel = (children: any[]) => {
        return children.map((child: any, key: number) => {
            return (
                <SwiperSlide key={key}>
                    <div className={classes.child} style={{ width }}>
                        {child}
                    </div>
                </SwiperSlide>
            )
        })
    }

    return (
        <div className={classes.root}>
            <div>
                <Swiper
                    slidesPerView={slidesPerView}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    spaceBetween={spaceBetween}
                    className={classes.swiper}
                    freeMode={true}
                    modules={[FreeMode]}
                    // freeMode={{
                    //     enabled: true,
                    //     momentum: true,
                    // }}
                >
                    {carousel(children)}
                </Swiper>
            </div>
        </div>
    )
}
