import React from "react"
import { Bar } from "react-chartjs-2"
import { add } from "dinero.js"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { useMoney } from "shared/Modules/Localization/useMoney"
import {
    DailyDaysTypes,
    ExpensesReportType,
    IExpensesReport,
    OrdersPeriod,
} from "shared/Modules/OrdersSummary/ordersSummaryTypes"
import { toCurrency, toDinero, zeroDinero } from "shared/Helpers/CurrencyHelper"
import { IMonetaryAmount } from "shared/Types/appTypes"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const useStyles = makeStyles((theme) => ({
    accentText: {
        fontWeight: 500,
    },
    title: {
        color: theme.palette.grey[700],
    },
}))

interface ExpensesGraphProps {
    report: IExpensesReport
}

export default function ExpensesGraph({ report }: ExpensesGraphProps) {
    const dateTimeFactory = useDateTime()
    const moneyFactory = useMoney()
    const classes = useStyles()

    const labels =
        report.type === ExpensesReportType.DAILY
            ? report.days.map((day: DailyDaysTypes) =>
                  dateTimeFactory.fromISO(day.date).toFormat(report.period === OrdersPeriod.LAST_WEEK ? "ccc" : "dd")
              )
            : report.months.map(({ month }) => month.toString())

    const amounts =
        report.type === ExpensesReportType.DAILY
            ? report.days.map((day: DailyDaysTypes) =>
                  moneyFactory.formatAsNumber(toDinero(day.amount)).replace(/,/g, "")
              )
            : report.months.map((month) => moneyFactory.formatAsNumber(toDinero(month.amount)).replace(/,/g, ""))

    function calculateTotalAmount(report: IExpensesReport) {
        const getTotal = (amounts: { amount: IMonetaryAmount }[]) => {
            if (amounts.length === 0) {
                // TODO: We should send a default currency in config based on user country
                return zeroDinero(toCurrency("DKK"))
            }
            const filteredData = amounts.map((el) => toDinero(el.amount))
            return filteredData.reduce((prev, cur) => add(prev, cur))
        }

        const total = report.type === ExpensesReportType.DAILY ? getTotal(report.days) : getTotal(report.months)

        return total ? moneyFactory.format(total) : "0"
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: "",
                data: amounts ?? [],
                backgroundColor: "#0090FF",
            },
        ],
    }

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    }

    return (
        <Grid container spacing={1}>
            <Grid item>
                <LocalizedStrict id="my-orders-expenses-chart-title">
                    <Typography variant="body2" className={classes.title}>
                        Private Purchases
                    </Typography>
                </LocalizedStrict>
                <Typography variant="h5" className={classes.accentText}>
                    {calculateTotalAmount(report)}
                </Typography>
            </Grid>
            <Bar data={data} options={options} />
        </Grid>
    )
}
