import React, { useState } from "react"
import clsx from "clsx"
import { Tab, Tabs, makeStyles } from "@material-ui/core"
import { OrdersPeriod } from "shared/Modules/OrdersSummary/ordersSummaryTypes"
import { LocalizedEnum } from "shared/Modules/Localization/Components/Localized"
import { getOrdersSummary } from "shared/Modules/OrdersSummary/ordersSummaryApi"
import { useQuery } from "shared/Modules/Query/useQuery"
import { useToken } from "shared/Modules/Login/useToken"
import ExpensesGraph from "./ExpensesGraph"

const useStyles = makeStyles((theme) => ({
    tabs: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    tab: {
        fontWeight: "normal",
        borderColor: "transparent",
    },
    activeTab: {
        fontWeight: "bold",
    },
}))

const tabPeriodMap: OrdersPeriod[] = [OrdersPeriod.LAST_WEEK, OrdersPeriod.LAST_MONTH, OrdersPeriod.LAST_YEAR]

const getPeriodByTabIndex = (index: number): OrdersPeriod => {
    return tabPeriodMap[index]
}

export function ExpensesTabs() {
    const token = useToken()
    const [activeTab, setActiveTab] = useState(0)
    const classes = useStyles()

    const activePeriod = getPeriodByTabIndex(activeTab)

    const { QueryPane } = useQuery(() => getOrdersSummary(activePeriod, token), [activeTab, token])

    return (
        <>
            <Tabs value={activeTab} indicatorColor="primary" className={classes.tabs}>
                {Object.keys(OrdersPeriod).map((tab, index) => (
                    <LocalizedEnum base="my-orders-expenses-chart-tab" enumValue={tab} attrs={{ label: true }}>
                        <Tab
                            label={tab}
                            onClick={() => setActiveTab(index)}
                            className={clsx(classes.tab, activeTab === index && classes.activeTab)}
                        />
                    </LocalizedEnum>
                ))}
            </Tabs>
            <QueryPane>{(data) => <ExpensesGraph report={data.expensesReport} />}</QueryPane>
        </>
    )
}
