import { ApiResponse } from "shared/Types/responseTypes"
import ApiHelper from "shared/Helpers/ApiHelper"
import { http, getHeaders } from "shared/Helpers/http"
import { IExpensesReport, OrdersPeriod, ReportDetails } from "./ordersSummaryTypes"

export interface IOrdersSummary {
    reportDetails: ReportDetails
    expensesReport: IExpensesReport
}

export const getOrdersSummary = (period: OrdersPeriod, token: string): Promise<ApiResponse<IOrdersSummary>> => {
    return http(
        new Request(ApiHelper.path(`/me/orders/summary?period=${period}`), {
            method: "GET",
            headers: getHeaders({ token }),
        })
    )
}
