import { Card, CardContent, Grid, Typography, makeStyles } from "@material-ui/core"
import { cyan } from "@material-ui/core/colors"
import React from "react"
import { Section } from "shared/Components/Skeleton/Section"
import { useQueryWithOptions } from "shared/Modules/Query/useQuery"
import { getRefillPaymentDetails } from "../walletAPI"
import { useToken } from "shared/Modules/Login/useToken"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { Skeleton } from "@material-ui/lab"
import { useMoney } from "shared/Modules/Localization/useMoney"
import { toDinero } from "shared/Helpers/CurrencyHelper"
import { Status } from "shared/Components/Skeleton/Status"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useAppNavigation } from "shared/hooks/useAppNavigation"

const useStyles = makeStyles((theme) => ({
    card: {
        color: theme.palette.common.white,
        backgroundColor: cyan[500],
        border: "none",
    },
    content: {
        padding: theme.spacing(2),
        "&:last-child": {
            paddingBottom: theme.spacing(2),
        },
    },
    outerGrid: {
        rowGap: theme.spacing(2),
    },
    status: {
        alignSelf: "flex-end",
    },
    balance: {
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.2,
    },
    walletName: {
        lineHeight: 1.2,
    },
    walletType: {
        lineHeight: 1.2,
    },
}))

type MyWalletsProps = Readonly<{}>

export function MyWallets({}: MyWalletsProps) {
    const classes = useStyles()

    const moneyFactory = useMoney()
    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { goToWallet } = useAppNavigation()
    const { QueryPane } = useQueryWithOptions(() => getRefillPaymentDetails(token, currentEnv), {
        dependencies: [token, currentEnv],
    })

    return (
        <LocalizedStrict id="me-my-wallets-section" attrs={{ title: true }}>
            <Section title="Mine Wallets">
                <QueryPane loadingElement={<Skeleton variant="rect" animation="wave" height={157} />}>
                    {(data) => (
                        <Card variant="outlined" className={classes.card} onClick={goToWallet}>
                            <CardContent className={classes.content}>
                                <Grid
                                    container
                                    direction="column"
                                    wrap="nowrap"
                                    alignItems="flex-start"
                                    className={classes.outerGrid}
                                >
                                    <LocalizedStrict id="me-my-wallets-status-auto-refill-active">
                                        <Status
                                            hidden={!Boolean(data.refill.automatic?.subscription)}
                                            className={classes.status}
                                        >
                                            Auto tank-op
                                        </Status>
                                    </LocalizedStrict>
                                    <Typography variant="h4" className={classes.balance}>
                                        {moneyFactory.format(toDinero(data.userAccountBalance))}
                                    </Typography>
                                    <Grid
                                        item
                                        container
                                        wrap="nowrap"
                                        justifyContent="space-between"
                                        alignItems="baseline"
                                    >
                                        <LocalizedStrict id="me-my-wallets-wallet-name">
                                            <Typography variant="body1" className={classes.walletName}>
                                                Wallet navn
                                            </Typography>
                                        </LocalizedStrict>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </QueryPane>
            </Section>
        </LocalizedStrict>
    )
}
