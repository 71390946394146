import React from "react"
import clsx from "clsx"
import getFromPath from "lodash/get"
import { makeStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/pro-solid-svg-icons/faHeart"
import { CardContent, CardMedia, Grid, IconButton, Typography } from "@material-ui/core"
import Card from "shared/Components/Card/Card"
import { Roboto } from "shared/theme"

const lineHeight = 1.5

function getTruncation(numberOfLines: number) {
    return `calc(${lineHeight}em * ${numberOfLines})`
}

const useStyles = makeStyles((theme) => ({
    cardContent: {
        padding: "0.5rem 0",
        "&:last-child": {
            padding: "0.5rem 0",
        },
    },
    cardContainer: {
        flexWrap: "nowrap",
        alignItems: "start",
    },
    title: {
        color: "#334155",
        fontFamily: Roboto,
        letterSpacing: 0,
        float: "left",
        display: "block",
        maxWidth: "100%",
        overflow: "hidden",
        maxHeight: "4.5em",
    },
    cardMedia: {
        width: "100%",
        height: 172,
        objectFit: "cover",
    },
    actionIconButton: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    titleWithoutImage: {
        fontSize: theme.typography.body1.fontSize,
    },
    textTruncation: {
        maxHeight: getTruncation(2),
        overflow: "hidden",
    },
}))

interface IProps {
    resource: any
    onClick: (resource: any) => void
    resourceTitleProp: string
    resourceImageProp: string
    headerTagTextProp: string
    resourcePriceProp?: string
    resourceSubTitleProp?: string
    headerTagColorProp?: string
    onCardAction?: (e: any, id?: number) => any
}

function HomeResourceCard(props: IProps) {
    const classes = useStyles()

    function getImage() {
        return getFromPath(props.resource, props.resourceImageProp) || null
    }

    function handleAction(e: any, id: number) {
        props.onCardAction && props.onCardAction(e, id)
    }

    return (
        <Card onClick={() => props.onClick(props.resource)} boxShadow="none">
            {getImage() && (
                <CardMedia
                    component="img"
                    image={getImage()}
                    style={{ borderRadius: 5 }}
                    title={getFromPath(props.resource, props.resourceTitleProp)}
                    alt={getFromPath(props.resource, props.resourceTitleProp)}
                    className={classes.cardMedia}
                />
            )}
            <CardContent className={classes.cardContent}>
                <Grid container justifyContent="space-between" alignItems="center" className={classes.cardContainer}>
                    <Typography
                        variant="subtitle2"
                        className={clsx(
                            classes.title,
                            { [classes.titleWithoutImage]: !getImage() },
                            { [classes.textTruncation]: !getImage() }
                        )}
                    >
                        {getFromPath(props.resource, props.resourceTitleProp)}
                    </Typography>
                    {props.onCardAction && (
                        <IconButton
                            onClick={(e) => handleAction(e, props.resource.id)}
                            color="primary"
                            size="small"
                            className={classes.actionIconButton}
                        >
                            <FontAwesomeIcon icon={faHeart} />
                        </IconButton>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default HomeResourceCard
