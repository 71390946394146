import React from "react"
import clsx from "clsx"
import getFromPath from "lodash/get"
import { CardMedia, CardContent, makeStyles, Typography, Grid } from "@material-ui/core"
import { IMonetaryAmount } from "shared/Types/appTypes"
import Card from "shared/Components/Card/Card"
import { Roboto } from "shared/theme"

const useStyles = makeStyles((theme) => ({
    productName: {
        color: "#334155",
        fontFamily: Roboto,
        letterSpacing: 0,
        float: "left",
        fontSize: ".95rem",
        fontWeight: "bold",
        textOverflow: "ellipsis !important",
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "100%",
        marginRight: theme.spacing(1),
    },
    productPrice: {
        color: "#505050",
        fontFamily: Roboto,
        fontSize: theme.typography.body2.fontSize,
        letterSpacing: -0.25,
        textAlign: "right",
        float: "right",
        whiteSpace: "nowrap",
    },
    productCardContent: {
        padding: "0.5rem 0",
    },
    updateProductCardContent: {
        "&.MuiCardContent-root:last-child": {
            paddingBottom: "0.25rem",
        },
    },
    relative: {
        position: "relative",
    },
    description: {
        clear: "both",
        color: "#64748B",
        margin: 0,
        fontFamily: Roboto,
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
    },
}))

interface IProps {
    resource: any
    onClick: (resource: any) => void
    resourceTitleProp: string
    resourceImageProp: string
    headerTagTextProp: string
    resourcePriceProp?: string
    resourceSubTitleProp?: string
    headerTagColorProp?: string
    updateProductCardUi?: boolean
}

export default function ResourceCard(props: IProps) {
    const classes = useStyles()

    function headerTag() {
        if (!getFromPath(props.resource, props.headerTagTextProp)) {
            return <div />
        }

        return (
            <p
                style={{
                    position: "absolute",
                    top: 0,
                    left: 15,
                    color: "#FFFFFF",
                    fontFamily: Roboto,
                    fontSize: "0.875rem", // = 14px @ std
                    letterSpacing: -0.25,
                    borderRadius: 11,
                    backgroundColor:
                        props.headerTagColorProp && getFromPath(props.resource, props.headerTagColorProp)
                            ? getFromPath(props.resource, props.headerTagColorProp)
                            : "rgba(0,0,0,0.31)",
                    padding: "4px 10px",
                }}
            >
                {getFromPath(props.resource, props.headerTagTextProp)}
            </p>
        )
    }

    function pricing(resource: any) {
        if (!props.resourcePriceProp || !getFromPath(props.resource, props.resourcePriceProp)) {
            return <div />
        }

        return (
            <p className={classes.productPrice}>
                {(getFromPath(props.resource, props.resourcePriceProp) as IMonetaryAmount).formatted}
            </p>
        )
    }

    return (
        <div onClick={() => props.onClick(props.resource)} style={{ width: "100%" }}>
            <Card height="100%" width="100%" boxShadow="none">
                <div className={classes.relative}>
                    <CardMedia
                        component="img"
                        alt={getFromPath(props.resource, props.resourceTitleProp)}
                        height="172"
                        style={{ borderRadius: 5 }}
                        image={getFromPath(
                            props.resource,
                            props.resourceImageProp,
                            "https://facilitynet.dk/remote/burgers.jpg"
                        )}
                        title={getFromPath(props.resource, props.resourceTitleProp)}
                    />
                    {headerTag()}
                </div>
                <CardContent
                    className={clsx(
                        classes.productCardContent,
                        !!props.updateProductCardUi && classes.updateProductCardContent
                    )}
                >
                    <Grid container justifyContent="space-between" wrap="nowrap">
                        <Typography variant="subtitle2" className={classes.productName}>
                            {getFromPath(props.resource, props.resourceTitleProp)} title
                        </Typography>
                        {pricing(props.resource)}
                    </Grid>
                    {props.resourceSubTitleProp && getFromPath(props.resource, props.resourceSubTitleProp) ? (
                        <Typography variant="body2" className={classes.description}>
                            {getFromPath(props.resource, props.resourceSubTitleProp)}
                        </Typography>
                    ) : (
                        ""
                    )}
                </CardContent>
            </Card>
        </div>
    )
}
