import React from "react"
import getFromPath from "lodash/get"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/pro-solid-svg-icons/faHeart"
import { CardMedia, Grid, IconButton, Typography } from "@material-ui/core"
import { CardTitle } from "shared/Components/Skeleton/CardTitle"
import { IMonetaryAmount } from "shared/Types/appTypes"
import Card from "shared/Components/Card/Card"

const priceAndActionWidth = 95

function getSideSpacing(theme: Theme) {
    return theme.spacing(2)
}

const useStyles = makeStyles((theme) => ({
    gridPadding: {
        padding: getSideSpacing(theme),
    },
    subTitleDiv: {
        maxWidth: `calc(100% - ${priceAndActionWidth}px)`,
    },
    pricing: {
        letterSpacing: 0,
        textTransform: "none",
        lineHeight: 1,
        color: theme.palette.grey.A200,
    },
    priceAndActionGrid: {
        width: priceAndActionWidth,
    },
    actionIconButton: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
}))

interface IProps {
    resource: any
    onClick: (resource: any) => void
    resourceTitleProp: string
    resourceImageProp: string
    resourcePriceProp?: string
    onCardAction?: (e: any, id?: number) => any
}

export function FavoriteResourceCard({
    resource,
    resourceImageProp,
    resourcePriceProp,
    onCardAction,
    resourceTitleProp,
    onClick,
}: IProps) {
    const classes = useStyles()

    function getImage() {
        return getFromPath(resource, resourceImageProp) || null
    }

    function getPricing() {
        if (!resourcePriceProp || !getFromPath(resource, resourcePriceProp)) {
            return <div />
        }

        return (
            <Typography variant="overline" className={classes.pricing}>
                {(getFromPath(resource, resourcePriceProp) as IMonetaryAmount).formatted}
            </Typography>
        )
    }

    function handleAction(e: any, id: number) {
        if (onCardAction) {
            onCardAction(e, id)
        }
    }

    return (
        <Card boxShadow="none" onClick={() => onClick(resource)}>
            {getImage() && (
                <CardMedia
                    component="img"
                    image={getImage()}
                    title={getFromPath(resource, resourceTitleProp)}
                    alt={getFromPath(resource, resourceTitleProp)}
                />
            )}
            <Grid container justifyContent="space-between" alignItems="center" className={classes.gridPadding}>
                <div className={classes.subTitleDiv}>
                    <CardTitle>{getFromPath(resource, resourceTitleProp)}</CardTitle>
                </div>
                <Grid container justifyContent="flex-end" alignItems="center" className={classes.priceAndActionGrid}>
                    {getPricing()}
                    {onCardAction && (
                        <IconButton
                            onClick={(e) => handleAction(e, resource.id)}
                            color="primary"
                            size="small"
                            className={classes.actionIconButton}
                        >
                            <FontAwesomeIcon icon={faHeart} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Card>
    )
}
