import clsx from "clsx"
import React, { useState, useEffect } from "react"
import {
    Divider,
    Table,
    TableCell,
    TableBody,
    makeStyles,
    TableRow,
    Typography,
    IconButton,
    Grid,
} from "@material-ui/core"
import { IMonetaryAmount, IMyOrderDetails } from "shared/Types/appTypes"
import Button from "shared/Components/Button"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { useQuery } from "shared/Modules/Query/useQuery"
import LoadingSpinner from "shared/Modules/Query/Components/LoadingSpinner"
import { useToken } from "shared/Modules/Login/useToken"
import { getMyOrdersPaginated } from "mobile/Api/GetMyOrdersPaginated"
import DetailedReceiptModal from "shared/Modules/OrderDetails/Screens/DetailedReceiptModal"
import RightArrowIcon from "../../Components/RightArrowIcon"
import { PaymentMethodIcon } from "shared/Modules/Transaction/Components/PaymentMethodDrawer"
import { useMoney } from "shared/Modules/Localization/useMoney"
import { toDinero } from "shared/Helpers/CurrencyHelper"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

const useStyles = makeStyles((theme) => ({
    tableRow: {
        height: 48,
    },
    tableCell: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    tableCellIcon: {
        width: theme.spacing(6),
        textAlign: "center",
        paddingRight: theme.spacing(1.5),
        "& .fa-xl": {
            fontSize: "1.75em",
        },
    },
    tableCellArrowIcon: {
        width: theme.spacing(5),
    },
    button: {
        width: "calc(100% - 30px)",
        marginTop: theme.spacing(2),
        paddingBottom: 50,
    },
    resultAmount: {
        marginBottom: theme.spacing(4),
    },
    withoutBorderBottom: {
        borderBottom: "none",
    },
    paymentMethodIcon: {
        display: "inline-block",
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        objectFit: "contain",
        objectPosition: "center",
    },
    greyColor: {
        color: theme.palette.grey[600],
    },
    totalText: {
        fontWeight: "bold",
    },
}))

interface IProps {
    initialData: IMyOrderDetails[]
    initialOrdersSummary?: IMonetaryAmount
    initialNextLink?: string
}

export default function MyOrdersData(props: IProps) {
    const token = useToken()
    const dateTimeFactory = useDateTime()
    const classes = useStyles()
    const moneyFactory = useMoney()
    const [nextLink, setNextLink] = useState<string>()
    const [data, setData] = useState<IMyOrderDetails[]>([])
    const [totalAmount, setTotalAmount] = useState<IMonetaryAmount>()
    const [receiptOrder, setReceiptOrder] = useState<IMyOrderDetails>()

    const { response } = useQuery(() => getMyOrdersPaginated(nextLink ?? "", token), [nextLink], nextLink !== undefined)

    useEffect(() => {
        setNextLink(undefined)
        setData(props.initialData)
        setTotalAmount(props.initialOrdersSummary)
    }, [props.initialNextLink, props.initialData, props.initialOrdersSummary])

    useEffect(() => {
        if (!response.loading && !response.failed) setData((prev) => [...prev, ...response.response.data.orders])
    }, [response.loading])

    function nextPage() {
        if (nextLink === undefined) setNextLink(props.initialNextLink)
        else if (!response.loading && !response.failed) {
            setNextLink(response.response.data.pagination?.nextLink?.href)
            setTotalAmount(response.response.data.ordersSummary?.totalAmount)
        }
    }

    function showMeetingDetails(order: IMyOrderDetails) {
        setReceiptOrder(order)
    }

    function shouldDisplayLoadMoreButton() {
        if (!response.loading && !response.failed) {
            if (response.response.data.pagination !== undefined) return true
            else return false
        }

        if (props.initialNextLink) return true
        return false
    }

    return (
        <>
            <LocalizedStrict id="my-orders-results" vars={{ amount: data.length }}>
                <Typography component="p" variant="caption" color="textSecondary" className={classes.resultAmount}>
                    {`${data.length} resultater`}
                </Typography>
            </LocalizedStrict>

            <Divider />
            <Table padding="none" cellPadding="none">
                <TableBody>
                    {data.map((order, index) => (
                        <TableRow key={index} className={classes.tableRow} onClick={() => showMeetingDetails(order)}>
                            <TableCell className={clsx(classes.tableCell, classes.tableCellIcon)}>
                                <PaymentMethodIcon iconDetails={order.paymentDetails?.paymentMethod?.icon} />
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body2">
                                    {order?.deliveries?.[0].deliveryTime
                                        ? dateTimeFactory.formatDate(
                                              dateTimeFactory.fromISO(order.deliveries[0].deliveryTime)
                                          )
                                        : ""}
                                </Typography>
                                <Typography variant="body2" className={classes.greyColor}>
                                    {order.kitchen?.name}
                                </Typography>
                            </TableCell>
                            <TableCell align="right" className={classes.tableCell}>
                                <Typography>{moneyFactory.formatAsNumber(toDinero(order.price))}</Typography>
                            </TableCell>
                            <TableCell align="right" className={clsx(classes.tableCell, classes.tableCellArrowIcon)}>
                                <IconButton>
                                    <RightArrowIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}

                    {totalAmount && (
                        <TableRow>
                            <TableCell className={clsx([classes.tableCell, classes.withoutBorderBottom])} />
                            <TableCell className={clsx([classes.tableCell, classes.withoutBorderBottom])}>
                                <LocalizedStrict id="my-orders-price-total">
                                    <Typography className={classes.totalText}>Total</Typography>
                                </LocalizedStrict>
                            </TableCell>
                            <TableCell align="right" className={clsx([classes.tableCell, classes.withoutBorderBottom])}>
                                <Typography>
                                    <strong>{moneyFactory.formatAsNumber(toDinero(totalAmount))}</strong>
                                </Typography>
                            </TableCell>
                            <TableCell className={clsx([classes.tableCell, classes.withoutBorderBottom])} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {nextLink && response.loading && <LoadingSpinner centerVertical />}

            {shouldDisplayLoadMoreButton() && (
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item className={classes.button}>
                        <LocalizedStrict id="my-orders-load-more-button">
                            <Button wide withBottomNavbar onClick={nextPage}>
                                Load more
                            </Button>
                        </LocalizedStrict>
                    </Grid>
                </Grid>
            )}

            {receiptOrder && (
                <DetailedReceiptModal
                    order={receiptOrder}
                    open={receiptOrder !== undefined}
                    onClose={() => setReceiptOrder(undefined)}
                />
            )}
        </>
    )
}
