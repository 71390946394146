import { DateTime } from "luxon"
import { IMonetaryAmount } from "shared/Types/appTypes"

export enum OrdersPeriod {
    LAST_WEEK = "LAST_WEEK",
    LAST_MONTH = "LAST_MONTH",
    LAST_YEAR = "LAST_YEAR",
}

export type GraphPeriodType = keyof typeof OrdersPeriod

export enum ExpensesReportType {
    DAILY = "DAILY",
    MONTHLY = "MONTHLY",
}

export type ReportDetails = Readonly<{
    startDate: string
    endDate: string
    reportTime: DateTime | string
}>

export type DailyDaysTypes = Readonly<{
    date: string
    amount: IMonetaryAmount
}>

interface ILastWeekExpensesReport {
    type: ExpensesReportType.DAILY
    period: OrdersPeriod.LAST_WEEK
    days: DailyDaysTypes[]
}

interface ILastMonthExpensesReport {
    type: ExpensesReportType.DAILY
    period: OrdersPeriod.LAST_MONTH
    days: DailyDaysTypes[]
}

interface ILastYearExpensesReport {
    type: ExpensesReportType.MONTHLY
    period: OrdersPeriod.LAST_YEAR
    months: {
        month: number
        startDate: string
        amount: IMonetaryAmount
    }[]
}

export type IExpensesReport = ILastWeekExpensesReport | ILastMonthExpensesReport | ILastYearExpensesReport
