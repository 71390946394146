import React, { MouseEventHandler } from "react"
import { createStyles, IconButton, makeStyles, Theme, Typography } from "@material-ui/core"
import closeIcon from "shared/assets/images/navbar_icon_close.svg"
import { SafeArea } from "shared/Components/Skeleton/SafeArea"
import TextLogo from "shared/Components/Brand/TextLogo"
import Image from "shared/Components/Image"
import { Roboto } from "shared/theme"

interface IProps {
    title?: string
    logo?: boolean
    onClose: MouseEventHandler
    rightColumnContent?: any
    bottomElement: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        center: {
            width: "100%",
            padding: "0 2.5rem",
            textAlign: "center",
        },
        fixedContainer: {
            position: "fixed",
            top: 0,
            backgroundColor: "white",
            width: "100%",
            zIndex: 99,
        },
        wrap: {
            padding: "0.75rem 0.5rem",
        },
        iconButton: {
            padding: "0.5rem",
            color: "#64748B",
            position: "absolute",
            left: "0.5rem",
            top: "50%",
            transform: "translateY(-50%)",
        },
        right: {
            position: "absolute",
            right: 0,
            top: 0,
        },
        title: {
            color: "#334155",
            fontFamily: Roboto,
            fontWeight: 700,
        },
        root: {
            backgroundColor: "white",
        },
    })
)

export default function (props: IProps) {
    const classes = useStyles()

    const title = () => {
        if (props.logo) {
            return <TextLogo />
        }

        return (
            <Typography variant="subtitle1" className={classes.title}>
                {props.title}
            </Typography>
        )
    }

    const rightColumn = () => {
        if (!props.rightColumnContent) {
            return <div />
        }

        return props.rightColumnContent
    }

    return (
        <div className={classes.root + " print-hidden"}>
            <div className={classes.fixedContainer}>
                <SafeArea top={true} position="relative" className={classes.wrap}>
                    <IconButton
                        onClick={props.onClose}
                        color="inherit"
                        aria-label="menu"
                        className={classes.iconButton}
                    >
                        <Image>{closeIcon}</Image>
                    </IconButton>

                    <div className={classes.center}>{title()}</div>

                    <div className={classes.right}>{rightColumn()}</div>
                </SafeArea>
                {props.bottomElement}
            </div>
        </div>
    )
}
