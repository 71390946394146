import { UserSurveyQuestions } from "shared/Modules/Surveys/surveyTypes"
import { IPaymentMethod } from "shared/Modules/Transaction/transactionTypes"

export interface IMeetingDetails {
    readonly meetingDuration?: number
    readonly isPrivate: boolean
    readonly isInProgress: boolean
    readonly meetingName: string
    readonly location: string
    readonly meetingTimeAsText: string
    readonly startDateTime: string
    readonly endDateTime: string
}

export interface IOrderSummary {
    readonly participants?: number
    readonly hasCatering: boolean
    readonly hasAudioVideoEquipment: boolean
}

export interface IOrder {
    readonly id: number
    readonly uid: string
    readonly displayName?: string
    readonly permaLink: string
    readonly created?: string
    readonly meetingDetails?: IMeetingDetails
    readonly orderSummary?: IOrderSummary
}

export interface ITimeSlot {
    readonly startDatetime: string
    readonly endDatetime: string
}

export interface IPredefinedMessage {
    readonly id: number
    readonly message: string
}

export interface IBookingSuggestion {
    startDatetime: string
    endDatetime: string
}

export interface IResource {
    selected: boolean
    readonly bookingSuggestion: IBookingSuggestion
    readonly resource: {
        id: number
        name: string
        resourceNumber?: string
        description?: string
        imageUrl?: string
        email?: string
        serviceCenterName?: string
        room?: IRoom
    }
    readonly timeSlots?: ITimeSlot[]
}

export interface IResourceType {
    readonly resourceTypeId: number
    readonly resourceTypeName: string
    readonly resources?: IResource[]
    readonly results: number
}

export interface IOrganizer {
    readonly id: number
    readonly uid: string
    readonly initials: string
    readonly firstname: string
    readonly lastname: string
    readonly name: string
    readonly email: string
    readonly businessPhone: string
    readonly mobilePhone: string
    readonly departmentId: number
    readonly department: string
    readonly customerTypeId: number
    readonly usertypeId: number
    readonly organizerType: string
    readonly companyId: number
    readonly orderBaseId: number
}

export interface ICateringDetails {
    readonly cateringOrderId: number
    readonly uid: string
    readonly meetingStartTime: string
    readonly meetingEndTime: string
    readonly orderNote: string
    readonly sittings: number
    readonly deliveryLocation: string
    readonly deliveryTime: string
    readonly bookedTime: string
    readonly isLocked: boolean
    readonly isContantPayment: boolean
    readonly isConfirmed: boolean
    readonly deliveryType: string
    readonly totalAmount: number
    readonly vat: number
    readonly canteen: ICanteen
    readonly created: string
    readonly updated: string
    readonly updatedByUserId: number
}

export interface ICheckInDetails {
    readonly checkIn: boolean
    readonly checkInMinutesBeforeMeeting: number
    readonly checkInMinutesAfterMeeting: number
}

export interface IRequestAssistanceDetails {
    readonly requestAssistance: boolean
}

export interface IBookingDetails {
    resources?: IBookingResource[]
    readonly checkIn?: ICheckInDetails
    readonly requestAssistance?: IRequestAssistanceDetails
}

export enum OrderType {
    CATERING = "CATERING",
    LUNCH = "LUNCH",
    TAKE_AWAY = "TAKE_AWAY",
    REFILL_ACCOUNT = "REFILL_ACCOUNT",
    AUTO_REFILL_ACCOUNT = "AUTO_REFILL_ACCOUNT",
    LUNCH_SUBSCRIPTION = "LUNCH_SUBSCRIPTION",
    SUBSCRIBE_PAYMENT_CARD = "SUBSCRIBE_PAYMENT_CARD",
    // ONE_CLICK_PAYMENT = "ONE_CLICK_PAYMENT",
    MEETING = "MEETING",
    REFUND = "REFUND",
}

export enum WebshopType {
    CATERING = "CATERING",
    LUNCH = "LUNCH",
    TAKE_AWAY = "TAKE_AWAY",
    OTHER = "OTHER",
}

export enum BuyerParty {
    PRIVATE = "PRIVATE",
    COMPANY = "COMPANY",
}

export enum PaymentMethodType {
    FREE = "FREE",
    CREDIT_CARD = "CREDIT_CARD",
    ONE_CLICK_PAYMENT = "ONE_CLICK_PAYMENT",
    MOBILE_PAY = "MOBILE_PAY",
    SALARY_REDUCTION = "SALARY_REDUCTION",
    PAYROLL_DEDUCTION = "PAYROLL_DEDUCTION",
    LUNCH_REGISTRATION = "LUNCH_REGISTRATION",
    GOPAY_WALLET = "GOPAY_ACCOUNT",
    INVOICE = "INVOICE",
    SUBSCRIBE_PAYMENT_CARD = "SUBSCRIBE_PAYMENT_CARD",
}

export interface IPurchaseCard {
    readonly id: number
    readonly type: string
    readonly displayName?: string
    readonly number: string
    readonly hasExpired?: boolean
}

export type ProductPaymentDetails = Readonly<{
    method: PaymentMethodType
}>

export interface IOrderDeliveryLocation {
    readonly name: string
}

export interface IOrderDeliveryCancel {
    readonly cancelEnable: boolean
    readonly shortMessage: string
    readonly message: string
}

export interface IOrderDelivery {
    readonly sittings?: number
    readonly deliveryType?: string
    readonly deliveryLocation?: IOrderDeliveryLocation
    readonly deliveryTime?: string
    readonly orderLines: IOrderLine[]
    readonly orderNote?: string
    readonly cancelOrder?: IOrderDeliveryCancel
}

export type OrderAccountingDimension = Readonly<{
    id: number
    name: string
    value: string
}>

export type OrderAccountingDetails = Readonly<{
    dimensions?: OrderAccountingDimension[]
    costCenter?: string
}>

export interface OrderPaymentDetails {
    readonly method?: PaymentMethodType
    readonly paymentMethod?: IPaymentMethod
    readonly card?: IPurchaseCard
    readonly isCaptured: boolean
    readonly isOnlinePayment: boolean
    readonly bankAccountStatement: string
    readonly remoteIp: string
    readonly toBePaidAsPrivate: IMonetaryAmount
    readonly toBePaidAsCompany: IMonetaryAmount
    readonly status?: "PAID" | "UNPAID" | "INVOICED" | "REFUNDED" | "CANCELLED"
    readonly accounting?: OrderAccountingDetails
}

export interface IOrderDetails extends IOrder {
    readonly organizers?: IOrganizer[]
    readonly cateringDetails: ICateringDetails
    readonly orderLines?: IOrderLine[]
    readonly bookingDetails?: IBookingDetails
    readonly paymentDetails?: OrderPaymentDetails
    readonly deliveries?: IOrderDelivery[]
    readonly qrReceiptUrl: string
    readonly cancelEnable?: boolean
    readonly receipt?: IReceipt
    readonly kitchen?: ICanteen
    readonly shopChannel?: string
    readonly orderType: OrderType
}

export interface IMyOrderDetails extends IOrderDetails {
    readonly kitchen: ICanteen
    readonly price: IMonetaryAmount
    readonly payment?: { method: PaymentMethodType }
    readonly customer?: IUser
}

export interface IReceipt {
    readonly qrUrl?: string
    readonly barcodeUrl?: string
    readonly controlColor: string
    readonly controlWord: string
}

export interface IBookingResource {
    readonly id: number
    readonly name: string
    readonly resourceTypeId: number
    readonly resourceType: "ROOM"
    readonly serviceCenterId: number
}

export interface IOrderLine {
    readonly resource?: {
        readonly id: number
        readonly resourceTypeId: number
        readonly serviceCenterId: number
    }
    readonly id: number
    readonly uid: string
    readonly items: number
    readonly price?: IMonetaryAmount
    readonly name: string
    readonly description: string
    readonly imageUrl: string
    readonly buyerParty?: BuyerParty
    readonly productId?: number
    readonly paymentDetails?: ProductPaymentDetails
}

export interface IRoom {
    readonly floor: number
    readonly isPrivateOffice: boolean
    readonly sittings: number
    readonly squareMeters: number
    readonly tableSettings: string[]
    readonly walkDirection: string
}

export interface INews {
    readonly id: number
    readonly uid: string
    readonly subject: string
    readonly shortDescription?: string
    readonly imageUrl?: string
    readonly body: string
    readonly publishDate: string
    readonly displayPublishDate: string
    readonly created: string
    readonly updated: string
    readonly category: string
    readonly creator: string
    readonly createdByCompany: string
    readonly permaLink?: string
    readonly external?: boolean
}

export interface IResourceFilterElement {
    readonly elementType:
        | "MultiSelect"
        | "ComboBox"
        | "CheckBox"
        | "DropDown"
        | "PlusMinusButton"
        | "TimeSlider"
        | "DateTimePicker"
    readonly identifier: string
    readonly name: string
    readonly translationKey: string
    readonly values: Array<{ key: string | number; value: string | number }>
}

export interface IResourceFilter {
    readonly filterElements: IResourceFilterElement[]
    readonly name: string
    readonly translationKey: string
}

export interface IAddress {
    readonly streetName: string
    readonly streetNumber: string
    readonly postalCode: string
    readonly city?: string
    readonly country?: string
}

export interface Company {
    readonly uid: string
    readonly name: string
}

export type CanteenItem = Readonly<{
    id: number
    name: string
}>

export interface ICanteen extends IAddress {
    readonly id: number
    readonly name: string
    readonly phoneNumber?: string
    readonly email?: string
    readonly satelliteCanteenId?: number
    readonly santeenTypeId?: number
    readonly brandingDetails?: ICanteenBrandingDetails
    readonly vatnumber?: string
    readonly address?: IAddress
    readonly sellerSupplierParty?: Company
    readonly webshop?: Webshop
    readonly webshops?: Webshop[]
}

export interface ICanteenContactDetails {
    readonly openingTimes?: IOpeningTime[]
    readonly closingDates?: string[]
    readonly phoneNumber?: string
    readonly email?: string
}

export interface IOpeningTime {
    readonly displayName: string
    readonly dayOfWeek: number
    readonly openingTime: string
    readonly closingTime: string
}

export interface ICanteenBrandingDetails {
    readonly description?: string
    readonly chefName?: string
    readonly website?: string
    readonly imageUrl?: string
}

export interface Webshop {
    readonly id?: number
    readonly uid?: string
    readonly name?: string
    readonly shopNumber?: string
    readonly orderType?: OrderType
    readonly type?: WebshopType
    readonly currencyCode?: string
}

export interface ICanteenDetails extends ICanteen {
    readonly streetName: string
    readonly streetNumber: string
    readonly postalCode: string
    readonly city: string
    readonly brandingDetails: ICanteenBrandingDetails
    readonly contactDetails: ICanteenContactDetails
    readonly kitchenType: string
}

export interface ICanFavorite {
    readonly canFavorite: boolean
}

export interface IProductFavorite {
    readonly favoriteType: WebshopType
    readonly favoriteId: number
    readonly canFavorite: boolean
}

export interface IMenuProduct {
    readonly subject: string
    readonly description: string
    readonly price?: IMonetaryAmount
    readonly vat: number
    readonly headerTag: string
    readonly headerTagColor: string
    readonly tags?: string[]
    readonly preparation: string
    readonly declaration: string
    readonly allergens?: string[]
    readonly isOffer: boolean
    readonly userDetails?: ICanFavorite | IProductFavorite
    readonly imageUrl: string
}

export interface IMenu {
    readonly date: string
    readonly displayDate: string
    readonly products: IMenuProduct[]
}

export interface ITodaysMenu {
    readonly menues: IMenu[]
}

export interface IWeeksMenu {
    readonly menues: IMenu[]
}

export interface IMonetaryBase {
    readonly amount: number
    readonly scale: number
    readonly currency: string
}

export interface IMonetaryAmount extends IMonetaryBase {
    readonly formatted: string
}

type UserNoLocation = Readonly<{
    selectLocation: true
}>
type UserHasLocation = Readonly<{
    selectLocation: false
    id?: number
    name?: string
    kitchen?: CanteenItem
}>
export type UserLocation = UserNoLocation | UserHasLocation

export interface IUser {
    readonly id: number
    readonly uid: string
    readonly uidHash: string
    readonly username: string
    readonly initials: string
    readonly displayInitials?: string
    readonly displayName: string
    readonly givenName: string
    readonly surName: string
    readonly email: string
    readonly phoneNumber: string
    readonly mobileNumber: string
    readonly customerTypeId: number
    readonly accountBalance?: IMonetaryAmount
    readonly isSubscribedToLunch: boolean
    readonly location?: UserLocation
    readonly isPrivateAccount?: boolean
    readonly company: Company
    readonly userSettings: UserSetting[]
    readonly department?: IDepartment
}

interface IDepartment {
    id: number
    name: string
}

export enum UserSettingElementType {
    TOGGLE = "TOGGLE",
    DROP_DOWN = "DROP_DOWN",
}

export type UserSetting = Readonly<{
    name: string
    translationKey: string
    elementType: UserSettingElementType
    value: string
}>

export interface INotification {
    readonly id: number
    readonly subject: string
    readonly message: string
    readonly created: string
    readonly notificationType: string
    readonly uid: string
}

export interface IPagination {
    readonly nextLink?: IPropertyLink
}

export interface IPropertyLink {
    readonly rel: string
    readonly href: string
    readonly translationKey?: string
}

export interface IUserWelcomeSection {
    subjectKey: string
    bodyKey: string
    backgroundImageUrl: string
}

export interface ITranslations {
    [key: string]: string
}

export interface ILocalization {
    readonly locale: string
    readonly translations: {
        [language: string]: ITranslations
    }
    readonly reloadCounter: number
}
