import React from "react"
import HeaderSection from "shared/Components/Skeleton/HeaderSection"
import { HeaderTopBar } from "shared/Components/Skeleton/TopBar"
import { ExpensesTabs } from "./ExpensesTabs"

type MyOrdersHeaderProps = {
    title: string
    onBackButtonPress: () => void
}

export function MyOrdersHeader(props: MyOrdersHeaderProps) {
    const { title, onBackButtonPress } = props

    return (
        <>
            <HeaderTopBar onBackButtonPress={onBackButtonPress} />
            <HeaderSection title={title}>
                <ExpensesTabs />
            </HeaderSection>
        </>
    )
}
