import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Screen from "shared/Components/Skeleton/Screen"
import { Avatar, Container, Typography, Grid, Divider, Badge, ButtonBase } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { Localized } from "@fluent/react"
import { WellknownColor, WellknownHeight } from "shared/Helpers/constants"
import { logout } from "shared/Modules/User/userActions"
import { selectUser } from "shared/Modules/User/userSelectors"
import BottomNavBar from "../../Components/Skeleton/BottomNavBar"
import Shortcuts from "./components/Shortcuts"
import MyFacilityNet from "./components/MyFacilityNet"
import SettingsDialog from "./components/SettingsDialog"
import MyMeetingsDialog from "./components/MyMeetingsDialog"
import { CustomerService } from "./components/CustomerService"
import { UserPrompts } from "shared/Modules/User/Components/UserPrompts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen } from "@fortawesome/pro-solid-svg-icons"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import { UserCardPages } from "shared/Modules/UserCards/userCardsTypes"
import { UserCards } from "shared/Modules/UserCards/Components/UserCards"
import { MyWallets } from "shared/Modules/Wallet/Components/MyWallets"
import { selectConfigProperties } from "shared/Modules/Properties/propertySelectors"
import { SafeArea } from "shared/Components/Skeleton/SafeArea"

const useStyles = makeStyles((theme) => ({
    miniProfile: {
        backgroundColor: WellknownColor.DEFAULT_HEADER_BACKGROUND,
        paddingTop: "64px",
    },
    miniProfileContainer: {
        padding: theme.spacing(4, 0),
        rowGap: theme.spacing(2),
    },
    outerContainer: {
        padding: theme.spacing(1),
        paddingTop: "2.25rem",
        paddingBottom: theme.spacing(14),
    },
    label: {
        color: theme.palette.grey["500"],
    },
    avatar: {
        width: "6rem",
        height: "6rem",
        fontSize: "2.9rem",
        backgroundColor: "#004B70",
    },
    avatarButton: {
        borderRadius: "50%",
    },
    badge: {
        backgroundColor: theme.palette.grey["300"],
        color: theme.palette.grey["900"],
        borderRadius: "50%",
        padding: theme.spacing(1),
    },
    cardsSectionContainer: {
        marginTop: theme.spacing(5),
        marginBottom: 0,
    },
}))

type CustomHeaderProps = {
    userName?: string
    avatarInitials: string
    onProfileClick?: () => void
}
function MiniProfile(props: CustomHeaderProps) {
    const { userName, avatarInitials, onProfileClick } = props
    const classes = useStyles()
    return (
        <SafeArea top className={classes.miniProfile}>
            <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="center"
                alignItems="center"
                className={classes.miniProfileContainer}
            >
                <Grid item>
                    <ButtonBase className={classes.avatarButton} focusRipple onClick={onProfileClick}>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            badgeContent={<FontAwesomeIcon className={classes.badge} icon={faPen} />}
                        >
                            <Avatar color="primary" className={classes.avatar}>
                                {avatarInitials}
                            </Avatar>
                        </Badge>
                    </ButtonBase>
                </Grid>
                <Grid item>
                    <Localized id="me-user-greeting" vars={{ firstName: userName ?? "" }}>
                        <Typography variant="h6" align="center">{`Hej ${userName}`}</Typography>
                    </Localized>
                </Grid>
            </Grid>
            <Divider />
        </SafeArea>
    )
}

function Me() {
    const classes = useStyles()
    const user = useSelector(selectUser)
    const properties = useSelector(selectConfigProperties)
    const dispatch = useDispatch()
    const { goToUserAccount } = useAppNavigation()

    const [isSettingsOpen, setIsSettingsOpen] = useState(false)
    const [isMyMeetingsOpen, setIsMyMeetingsOpen] = useState(false)

    function logoutUser() {
        dispatch(logout())
    }

    function handleSettings() {
        setIsSettingsOpen(!isSettingsOpen)
    }

    function handleMyMeetings() {
        setIsMyMeetingsOpen(!isMyMeetingsOpen)
    }

    return (
        <Screen
            name="Me"
            showHeaderSection={false}
            alternativeHeaderElement={
                <MiniProfile
                    userName={user?.givenName}
                    avatarInitials={user?.displayInitials ?? ""}
                    onProfileClick={goToUserAccount}
                />
            }
            bottomElement={<BottomNavBar />}
            bottomElementHeight={WellknownHeight.BOTTOM_NAV_BAR}
        >
            <Container className={classes.outerContainer}>
                {properties.showRefillAccountModule && <MyWallets />}
                <MyFacilityNet openMyMeetings={handleMyMeetings} />
                <UserCards page={UserCardPages.me} containerClassName={classes.cardsSectionContainer} />
                <CustomerService />
                <Shortcuts openSettings={handleSettings} logoutUser={logoutUser} />
            </Container>
            <SettingsDialog isOpen={isSettingsOpen} onClose={handleSettings} />
            <MyMeetingsDialog isOpen={isMyMeetingsOpen} onClose={handleMyMeetings} />
            <UserPrompts page="me" />
        </Screen>
    )
}

export default Me
