import React from "react"
import clsx from "clsx"
import getFromPath from "lodash/get"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Card, Chip, Grid, Typography } from "@material-ui/core"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { IMenuProduct } from "shared/Types/appTypes"
import { CardTitle } from "shared/Components/Skeleton/CardTitle"
import { CardDescription } from "shared/Components/Skeleton/CardDescription"

const mediaHeight = 270

interface IProps {
    resource: IMenuProduct
    resourceTitleProp: string
    resourceDescriptionProp?: string
    resourceImageProp: string
    headerTagTextProp: string
    resourcePriceProp?: string
    resourceSubTitleProp?: string
    headerTagColorProp?: string
    onCardAction?: (e: any, id?: number) => any
}

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: "none",
        borderRadius: "0.625rem",
    },
    topContent: {
        position: "relative",
    },
    imageContainer: {
        height: mediaHeight,
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderTopLeftRadius: "0.625rem",
        borderTopRightRadius: "0.625rem",
        overflow: "hidden",
    },
    chip: {
        borderRadius: "0.25rem",
        fontWeight: 500,
    },
    chipWrap: {
        paddingTop: "1rem",
        paddingLeft: "0.75rem",
    },
    chipWrapPosition: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
        paddingLeft: "1rem",
    },
    chipLabel: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 500,
        color: theme.palette.common.white,
    },
    content: {
        flexDirection: "column",
        gap: "1rem",
        color: theme.palette.text.secondary,
        padding: "0.75rem",
    },
    contentHeader: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
    },
    allergenBox: {
        display: "flex",
        flexDirection: "column",
        textTransform: "capitalize",
    },
    allergenText: {
        color: "#DC2626",
    },
}))

function ProductCard(props: IProps) {
    const classes = useStyles()

    const getImage = getFromPath(props.resource, props.resourceImageProp) || null
    const getTag = getFromPath(props.resource, props.headerTagTextProp) || null
    const getPricing =
        props.resourcePriceProp && props.resource
            ? getFromPath(props.resource, props.resourcePriceProp)?.formatted
            : null

    return (
        <Card className={classes.card}>
            <Box className={classes.topContent}>
                {/* Header Tag */}
                {getTag && (
                    <Box className={clsx(classes.chipWrap, getImage && classes.chipWrapPosition)}>
                        <Chip
                            label={getTag}
                            variant="outlined"
                            size="small"
                            className={classes.chip}
                            classes={{ label: classes.chipLabel }}
                            style={{
                                background:
                                    props.headerTagColorProp && getFromPath(props.resource, props.headerTagColorProp)
                                        ? getFromPath(props.resource, props.headerTagColorProp)
                                        : "rgba(0,0,0,0.31)",
                                borderColor:
                                    props.headerTagColorProp && getFromPath(props.resource, props.headerTagColorProp)
                                        ? getFromPath(props.resource, props.headerTagColorProp)
                                        : "rgba(0,0,0,0.31)",
                            }}
                        />
                    </Box>
                )}

                {/* Image */}
                {getImage && <Box className={classes.imageContainer} style={{ backgroundImage: `url(${getImage})` }} />}
            </Box>

            <Grid container className={classes.content}>
                <Box className={classes.contentHeader}>
                    <CardTitle>{getFromPath(props.resource, props.resourceTitleProp)}</CardTitle>
                    {props.resource?.description && <CardDescription>{props.resource?.description}</CardDescription>}
                </Box>
                {getPricing && <CardDescription>{getPricing}</CardDescription>}
                {props.resource?.allergens && props.resource.allergens.length > 0 && (
                    <Box className={classes.allergenBox}>
                        <LocalizedStrict id="canteenProductModal-allergens-label">
                            <Typography variant="caption" className={classes.allergenText}>
                                Allergens:
                            </Typography>
                        </LocalizedStrict>
                        <CardDescription>{props.resource.allergens.join(" - ")}</CardDescription>
                    </Box>
                )}
            </Grid>
        </Card>
    )
}

export default ProductCard
