import clsx from "clsx"
import React, { CSSProperties, PropsWithChildren } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { StrictOmit } from "shared/Types/helperTypes"

type CardDescriptionProps = Readonly<
    PropsWithChildren<{
        className?: string
        marginTop?: CSSProperties["marginTop"]
        marginBottom?: CSSProperties["marginBottom"]
    }>
>

type StyleProps = StrictOmit<CardDescriptionProps, "children" | "className">

const useStyles = makeStyles(() => ({
    cardDescription: (props: StyleProps) => ({
        color: "#64748B",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
    }),
}))

export function CardDescription(props: CardDescriptionProps) {
    const classes = useStyles(props)

    return (
        <Typography variant="body1" className={clsx(props.className, classes.cardDescription)}>
            {props.children}
        </Typography>
    )
}
